import React, { useState } from 'react';

import { message } from 'antd';
import cn from 'classnames';
import { useRouter } from 'next/router';

import { Icon } from '@components/index';
import { Share } from '@components/Share';
import { Button, Popover } from '@element/index';
import useAuthSession from '@lib/hooks/useAuthSession';
import I18n from '@utils/i18n';
import { copyText, getLoginPath } from '@utils/index';

import styles from './index.module.css';

const PopoverContent = (props) => {
  const { title, link } = props;
  const copyClick = () => {
    copyText(link);
    // navigator.clipboard.writeText(data?.inviteInfo?.linkText);
    message.success(I18n.t('copySuccess'));
  };
  const style = {
    width: 32,
    height: 32
  }
  const shareChannels = [
    {
      imgUrl: require('@images/whatsapp2.webp'),
      channel: 'whatsapp',
      style
    },
    {
      imgUrl: require('@images/facebook2.webp'),
      channel: 'facebook',
      style
    },
    {
      imgUrl: require('@images/line2.webp'),
      channel: 'line',
      style
    },
    {
      imgUrl: require('@images/telegram2.webp'),
      channel: 'telegram',
      style
    },
    {
      imgUrl: require('@images/twitter2.webp'),
      channel: 'twitter',
      style
    }
  ]
  return (
    <div className={styles.popoverContainer}>
      <div className={cn(styles.shareTitle, 'DINPro-Medium')}>{title}</div>
      <div className={styles.shareUrl}>
        <p className={cn(styles.link, styles.DINProMedium)}>{link}</p>
        <Button
          className={styles.copyBtn}
          onClick={() => copyClick()}
          type="ghost"
          borderRadius={17}
        >
          {I18n.t('copy')}
        </Button>
      </div>
      <div className={styles.shareWrap}>
        <div className={styles.shareTo}>{I18n.t('shareTo')}</div>
        <Share
          title={title}
          url={link}
          shareChannels={shareChannels}
          itemClassName={styles.IconItem}
        />
      </div>
    </div>
  );
};
const T = (props) => {
  const router = useRouter();
  const isLogin = useAuthSession({ redirectTo: null });
  const {
    align,
    title,
    link,
    curShareCount,
    addShareCountFun,
    className,
    placement = 'left'
  } = props;
  const [visible, setVisible] = useState(false);

  const containerName = cn(styles.container, className, {
    [styles.middle]: curShareCount === 0
  });
  return (
    <Popover
      hasArrow
      align={align}
      content={<PopoverContent title={title} link={link} />}
      overlayClassName={styles.popover}
      placement={placement}
      trigger="click"
      visible={visible}
      onVisibleChange={(v) => {
        // 没登录跳登录页
        if (!isLogin) {
          router.push(getLoginPath());
          return;
        }
        setVisible(v);
        if (!v && addShareCountFun) {
          addShareCountFun();
        }
      }}
    >
      <div className={containerName} data-buried="share">
        <Icon name="share" className={cn(styles.share)} />
        <span className={styles.count}>
          {curShareCount || ' '}
        </span>
      </div>
    </Popover>
  );
};

export default T;
