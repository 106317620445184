import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, InputTextArea } from '@element/index'
import styles from './index.module.css';
import cn from 'classnames';
import defaultAvatar from '@images/avata.webp';
import AvatarComponent from '@components/AvatarComponent';
import helper from '@utils/helper';
import { usePublishComment } from '@widget/post/hooks/publishComment'
import I18n from '@utils/i18n'

const maxLength = 200;
const T = (props) => {
  const { userStore, commentInputRef, itemData, fetchPostComment, className, setCommentCount, commentCount } = props
  const avatar = userStore?.socialUserInfo?.avatar || defaultAvatar;
  const identificationSource = helper.identificationSwitch(userStore?.socialUserInfo?.role);
  const [text, setText] = useState('');
  const [textLen, setTextLen] = useState(0);

  const { publishComment, submiting } = usePublishComment({ itemData, content: text, fetchPostComment, setText, setTextLen, setCommentCount, commentCount })

  const onChange = (e) => {
    const val = e.target.value
    setText(val)
    setTextLen(val.length)
  }

  const lenStyle = cn(styles.textLen, {
    [styles.displayNone]: textLen === 0,
    [styles.redText]: textLen === maxLength
  })
  return (
    <div className={cn(styles.container, className)} >
      <div className={styles.avatar}>
        <AvatarComponent
          avatar={avatar}
          avatarSize={32}
          identificationSource={identificationSource}
          identificationSize={12}
        />
      </div>
      <InputTextArea
        myRef={commentInputRef}
        className={cn(styles.input, {
          [styles.placeholder]: !text?.length
        })}
        rows={2}
        value={text}
        onChange={onChange}
        maxLength={maxLength}
        placeholder={I18n.t('community_publishComment')}
      />
      <div className={styles.btnContainer}>
        <span className={lenStyle}>{textLen}/{maxLength}</span>
        <Button
          type='primary'
          content={I18n.t('community_sendPost')}
          data-buried="community_sendPost"
          className={styles.btn}
          disabled={textLen === 0 || submiting}
          onClick={async () => {
            publishComment();
          }}
        />
      </div>
    </div>
  )
}

export default inject(rootStore => {
  return {
    userStore: rootStore.store.userStore
  }
})(observer(T))
