import React, { useState } from 'react';

import { Image as AntdImage } from 'antd';
import Image from 'next/legacy/image';

import { handleMaskScroll } from '@utils/index'

import styles from './FeedImage.module.css';

const ImageGroupPreview = (props) => {
  const [visible, setVisible] = useState(false);
  const {
    preview = {}, curSrc, width, height, currentIndex = 0, previewImgGroup = [],
    imgStyle = {}, maskClassName = '', className = '', alt = 'kikitrade'
  } = props;
  return (
    <>
      <AntdImage
        preview={{
          visible: false,
          maskClassName: maskClassName,
          ...preview
        }}
        width={width}
        height={height}
        src={curSrc}
        onClick={() => {
          handleMaskScroll(true)
          setVisible(true)
        }}
        style={imgStyle}
        alt={alt}
        className={[styles.feedImage, className]}
        placeholder={
          <Image
            className={styles.feedImage}
            src={require('../../images/kiki_preload.webp')}
            width={width}
            height={height}
            alt={alt}
          />
        }
      />
      <div style={{ display: 'none' }}>
        <AntdImage.PreviewGroup
          preview={{
            visible,
            current: currentIndex,
            onVisibleChange: vis => {
              handleMaskScroll(false)
              setVisible(vis)
            }
          }}
        >
          {
            previewImgGroup.map((item, index) => <AntdImage src={item} key={'image_group' + index} alt={alt}/>)
          }
        </AntdImage.PreviewGroup>
      </div>
    </>
  );
};
export default ImageGroupPreview
