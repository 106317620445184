import React from 'react';
import I18n from '@utils/i18n';
import styles from './index.module.css';
import cn from 'classnames';
import Icon from '@components/Icon';

const T = (props) => {
  const {
    type,
    likeFun,
    curLikeCount,
    isLoved,
    className
  } = props

  // type 1 帖子详情固定在左侧的操作 3 feed 流评论
  const containerName = cn(styles.container, className, {
    [styles.container1]: type === '1',
    [styles.container2]: type === '2',
    [styles.container3]: type === '3',
    [styles.middle]: curLikeCount === 0 && type === '1'
  })
  const countName = cn(styles.count, {
    [styles.countLike]: isLoved
  })
  const iconContainerStyle = cn(
    styles.iconContainer, {
      [styles.containerLike]: isLoved
    }
  )
  if (type === '1') {
    return (
      <div onClick={() => { likeFun() }} className={containerName} data-buried="like">
        <Icon name='like' className={cn(styles.like, {
          [styles.likeActive]: isLoved
        })} />
        <span className={countName}>
          {!curLikeCount ? ' ' : `${curLikeCount}`}
        </span>
    </div>
    )
  } else if (type === '2') {
    return (
      <div onClick={() => { likeFun() }} className={containerName} data-buried="like">
          <div className={iconContainerStyle}>
            <Icon name='love' className={styles.loveIcon} />
          </div>
          <span className={countName}>
            {!curLikeCount ? I18n.t('m5TEs0gJ') : `${curLikeCount} ${I18n.t('likes')}`}
          </span>
      </div>
    );
  } else if (type === '3') {
    return (
      <div onClick={() => { likeFun() }} className={containerName} data-buried="like">
        <Icon name='like' className={cn(styles.like, styles.likeHover, {
          [styles.likeActive]: isLoved
        })} data-active="" />
        <span className={countName}>
          {!curLikeCount ? ' ' : `${curLikeCount}`}
        </span>
    </div>
    )
  }
}

export default T
