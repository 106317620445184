import React from 'react';
import { Icon } from '@components/index'
import styles from './index.module.css';
import cn from 'classnames';
import useAuthSession from '@lib/hooks/useAuthSession'
import { getLoginPath } from '@utils/index'
import { useRouter } from 'next/router'

const T = (props) => {
  const router = useRouter();
  const isLogin = useAuthSession({ redirectTo: null })
  const {
    commentCount,
    commentFun,
    type = '1',
    actived = false,
    className
  } = props
  // type 1 | 3       1是详情页左边的悬浮按钮，3是feed流和评论里的按钮 两个颜色不一样
  const containerName = cn(styles.container, className, {
    [styles.middle]: commentCount === 0 && type === '1',
    [styles.container3]: type === '3',
    [styles.container1]: type === '1'
  })

  const clickFun = () => {
    // 没登录跳登录页
    if (!isLogin) {
      router.push(getLoginPath())
      return
    }
    commentFun()
  }

  return (
    <div onClick={clickFun} className={containerName} data-buried="comment">
      <Icon name='comment' className={cn(styles.comment1, {
        [styles.comment3]: type === '3',
        [styles.comment3Active]: actived && type === '3'
      })} data-active={actived && type === '3'} />
      <span className={styles.count}>
        {commentCount || ' '}
      </span>
    </div>
  )
}

export default T
