
import { StyleSheet, Dimensions } from 'react-native'
import p from '../../utils/Transfrom';

export const dimensions = {
  fullHeight: Math.round(Dimensions.get('window').height),
  fullWidth: Math.round(Dimensions.get('window').width)
}

// added below our colors, fonts, padding etc
export const colors = {
  pureWhite: '#fff',
  white1: '#F6F8FB',
  white2: '#F6F8FB',
  white3: '#F9F9FA',
  white4: '#FBFBFB',
  white5: '#D8D8D8',
  white6: 'rgba(255,255,255,0.8)',
  white7: 'rgba(255,255,255,0.2)',
  white8: '#EDEDED',
  white9: '#F2F1F5',
  white10: '#F5F5F5',
  white11: '#F6F5FA',
  white12: '#BCBFC5',
  white13: '#EBE7F6',
  gray1: '#696F7F',
  gray2: 'rgba(142,147,159,0.4)',
  gray3: '#8E939F',
  grey4: '#D9DBDF',
  grey5: '#979797',
  grey6: '#EBE9F1',
  black: '#000',
  black1: '#434B5F',
  black2: 'rgba(0,0,0,0.6)',
  black3: '#1F2126',
  black4: 'rgba(0,0,0,0.4)',
  black5: '#AFB3BD',
  black6: 'rgba(0,0,0,0.1)',
  red: '#ED220D',
  red1: '#F0506C',
  green: '#1EAE45',
  lightPurple: 'rgba(111,64,238,0.1)',
  purple: '#6F40EE',
  purple2: '#7600F6',
  transparent: 'transparent'

}
export const width = {
  w4: p(4),
  w12: p(12),
  w24: p(24),
  w28: p(28),
  w30: p(30),
  w32: p(32),
  w36: p(36),
  w39: p(39),
  w40: p(40),
  w48: p(48),
  w56: p(56),
  w64: p(64),
  w60: p(60),
  w80: p(80),
  w92: p(92),
  w100: p(100),
  w112: p(112),
  w120: p(120),
  w132: p(132),
  w166: p(166),
  w140: p(140),
  w192: p(192),
  w199: p(199),
  w230: p(230),
  w345: p(345),
  w360: p(360),
  w380: p(380),
  w400: p(400),
  w480: p(480),
  w500: p(500),
  w560: p(560),
  w561: p(561),
  w564: p(564),
  w590: p(590),
  w600: p(600),
  w620: p(620),
  w630: p(630),
  w690: p(690),
  w576: p(576)
}

export const height = {
  h1: p(1),
  h4: p(4),
  h6: p(6),
  h12: p(12),
  h17: p(17),
  h20: p(20),
  h24: p(24),
  h27: p(27),
  h28: p(28),
  h32: p(32),
  h36: p(36),
  h38: p(38),
  h39: p(39),
  h40: p(40),
  h48: p(48),
  h52: p(52),
  h54: p(54),
  h56: p(56),
  h64: p(64),
  h60: p(60),
  h68: p(68),
  h72: p(72),
  h80: p(80),
  h87: p(87),
  h88: p(88),
  h92: p(92),
  h120: p(120),
  h136: p(136),
  h140: p(140),
  h180: p(180),
  h192: p(192),
  h200: p(200),
  h208: p(208),
  h212: p(212),
  h248: p(248),
  h280: p(280),
  h288: p(288),
  h300: p(300),
  h340: p(340),
  h380: p(380),
  h529: p(529),
  h680: p(680),
  h759: p(759),
  h684: p(684)
}
export const padding = {
  p4: p(4),
  p6: p(6),
  p10: p(10),
  p15: p(15),
  p20: p(20),
  p24: p(24),
  p28: p(28),
  p32: p(32),
  p30: p(30),
  p35: p(35),
  p37: p(37),
  p40: p(40),
  p48: p(48),
  p50: p(50),
  p68: p(68),
  p100: p(100)
}
export const margin = {
  m1: p(1),
  m2: p(2),
  m4: p(4),
  m5: p(5),
  m6: p(6),
  m7: p(7),
  m8: p(8),
  m10: p(10),
  m12: p(12),
  m14: p(14),
  m15: p(15),
  m16: p(16),
  m17: p(17),
  m18: p(18),
  m20: p(20),
  m22: p(22),
  m24: p(24),
  m25: p(25),
  m28: p(28),
  m30: p(30),
  m32: p(32),
  m36: p(36),
  m38: p(38),
  m35: p(35),
  m40: p(40),
  m42: p(42),
  m48: p(48),
  m54: p(54),
  m56: p(56),
  m72: p(72),
  m88: p(88),
  m195: p(195),
  m321: p(321),
  m427: p(427),
  m217: p(217),
  m240: p(240)
}

export const fontWeight = {
  f100: '100',
  f200: '200',
  f300: '300',
  f400: '400',
  f500: '500',
  f600: '600',
  f700: '700',
  f800: '800',
  f900: '900',
  fnormal: 'normal',
  fbold: 'bold'
}

export const fontFamily = {
  regular: 'DINPro-Regular',
  bold: 'DINPro-Bold',
  medium: 'DINPro-Medium'
}

export const marginHorizontal = {
  mh28: p(28),
  mh30: p(30)
}

export const marginVertical = {
  mv48: p(48)
}

export const paddingHorizontal = {
  ph6: p(6),
  ph16: p(16),
  ph20: p(20),
  ph22: p(22),
  ph24: p(24),
  ph28: p(28),
  ph30: p(30),
  ph45: p(45)
}

export const paddingVertical = {
  pv18: p(18),
  pv22: p(22),
  pv24: p(24),
  pv28: p(28),
  pv30: p(30)

}

export const fontSize = {
  f18: p(18),
  f20: p(20),
  f22: p(22),
  f24: p(24),
  f26: p(26),
  f28: p(28),
  f30: p(30),
  f32: p(32),
  f34: p(34),
  f36: p(36),
  f40: p(40),
  f42: p(42),
  f46: p(46),
  f47: p(47),
  f48: p(48)
}

export const radius = {
  r4: p(4),
  r8: p(8),
  r12: p(12),
  r16: p(16),
  r18: p(18),
  r24: p(24),
  r26: p(26),
  r27: p(27),
  r32: p(32),
  r36: p(36),
  r40: p(40),
  r48: p(48),
  r56: p(56),
  r60: p(60),
  r64: p(64),
  r80: p(80),
  r90: p(90),
  r120: p(120)
}

export const lineHeight = {
  l23: p(23),
  l26: p(26),
  l28: p(28),
  l31: p(31),
  l32: p(32),
  l33: p(33),
  l36: p(36),
  l37: p(37),
  l38: p(38),
  l40: p(40),
  l42: p(42),
  l44: p(44),
  l45: p(45),
  l46: p(46),
  l48: p(48),
  l56: p(56),
  l59: p(59),
  l60: p(60),
  l88: p(88)
}

export const alignItems = {
  center: 'center'
}

export const justifyContent = {
  center: 'center',
  spaceBetween: 'space-between',
  flexEnd: 'flex-end'
}

export const textAlign = {
  left: 'left',
  center: 'center',
  right: 'right'
}

export const textAlignVertical = {
  center: 'center'
}

export const layoutCenter = {
  alignItems: alignItems.center,
  justifyContent: justifyContent.center,
  flex: 1,
  flexDirection: 'row'
}

export const zIndex = {
  z2: 2,
  z999: 999
}

export const imageRounded32 = {
  width: width.w32,
  height: height.h32,
  borderRadius: radius.r32
}

export const imageRounded40 = {
  width: width.w40,
  height: height.h40,
  borderRadius: radius.r40
}

export const imageRounded56 = {
  width: width.w56,
  height: height.h56,
  borderRadius: radius.r56
}

export const imageRounded60 = {
  width: width.w60,
  height: height.h60,
  borderRadius: radius.r60
}

export const imageRounded64 = {
  width: width.w64,
  height: height.h64,
  borderRadius: radius.r64
}

export const imageRounded80 = {
  width: width.w80,
  height: height.h80,
  borderRadius: radius.r80
}

export const imageRounded120 = {
  width: width.w120,
  height: height.h120,
  borderRadius: radius.r120
}

export const commonFontBook = {
  boldFont: {
    fontFamily: fontFamily.bold,
    fontWeight: fontWeight.f700
  },
  regularFont: {
    fontFamily: fontFamily.regular,
    fontWeight: fontWeight.f400
  },
  mediumFont: {
    fontFamily: fontFamily.medium,
    fontWeight: fontWeight.f500
  }

}

export const sectionTitleStyle = {
  ...commonFontBook.mediumFont,
  fontSize: fontSize.f30,
  lineHeight: lineHeight.l38,
  color: colors.black,
  marginTop: margin.m48,
  paddingHorizontal: paddingHorizontal.ph30
}

export const theme = {
  colors,
  padding,
  fontSize,
  radius
};

export const darkTheme = {
  ...theme,
  colors: {
    ...theme.colors
  }
}

// base styles

export const baseStyles = {
  container: {
    flex: 1,
    width: dimensions.fullWidth,
    backgroundColor: colors.pureWhite
  },
  header: {
    height: height.h88,
    paddingHorizontal: paddingHorizontal.ph30,
    alignItems: alignItems.center,
    justifyContent: justifyContent.spaceBetween,
    flexDirection: 'row'
  },
  sectionStyle: {
    marginTop: margin.m32,
    flexDirection: 'row'
  }
}

export default function createStyles (overrides = {}) {
  return StyleSheet.create({ ...baseStyles, ...overrides })
}
