import React from 'react';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import styles from './index.module.css';
import Like from '../Like/index';
import Fav from '../Fav/index';
import Comment from '../Comment/index';
import Share from '../Share/index';
import { useShare } from '@widget/post/hooks/share'
import { COMMUNITY_PATHS } from '@constants/index'

const T = (props) => {
  const {
    curLikeCount, isLoved, likeFun, favFun, isFav,
    itemData, curShareCount, setCurShareCount,
    curFavCount, setFavoriteCount, commentFun, sharePath = COMMUNITY_PATHS.POST_DETAIL,
    className, likeClass, favClass, commentClass, shareClass, itemClass, placement, commentCount
  } = props;
  const { handleShareText, addShareCountFun } = useShare({ itemData, setCurShareCount, sharePath })
  const { shareText, shareLink } = handleShareText();
  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(styles.items, itemClass)}>
        <Like
          curLikeCount={curLikeCount}
          isLoved={isLoved}
          likeFun={likeFun}
          className={likeClass}
          type='1'
        />
      </div>
      <div className={cn(styles.items, itemClass)}>
        <Comment
          commentFun={commentFun}
          commentCount={commentCount}
          className={commentClass}
        />
      </div>
      <div className={cn(styles.items, itemClass)}>
        <Fav
          isFav={isFav}
          favFun={favFun}
          curFavCount={curFavCount}
          setFavoriteCount={setFavoriteCount}
          className={favClass}
        />
      </div>
      <div className={cn(styles.items, itemClass)}>
        <Share
          title={shareText}
          link={shareLink}
          curShareCount={curShareCount}
          addShareCountFun={addShareCountFun}
          className={shareClass}
          placement={placement}
        />
      </div>
    </div>
  )
}

export default inject(rootStore => {
  return {
    userStore: rootStore.store.userStore
  }
})(observer(T))
