import { useState } from 'react';
import { I18n, getLoginPath } from '@utils/index'
import { message } from 'antd';
import useAuthSession from '@lib/hooks/useAuthSession'
import { getClient } from '@api/feedStream'
import { useRouter } from 'next/router'
// 点赞 取消点赞
export const useLike = ({ userStore, itemData = {}, setCurLikeCount, setIsLoved, isLoved, curLikeCount }) => {
  const toastKey = 'updatable';
  const router = useRouter();
  const isLogin = useAuthSession({ redirectTo: null });
  const [sending, setSending] = useState(false)

  const onLikeSuccess = (isLike) => {
    return (code) => {
      if (code === 0) {
        setSending(false)
        const msg1 = isLike ? I18n.t('community_cancelLike') : I18n.t('community_likeSuccess')
        message.success({ content: msg1, key: toastKey, duration: 3 })
        setCurLikeCount(isLike ? curLikeCount - 1 : curLikeCount + 1);
        setIsLoved(!isLike);
      }
    };
  }
  const onLikeFail = (isLike) => {
    return () => {
      setSending(false)
      const msg2 = isLike ? I18n.t('ommunity_cancelLikeFail') : I18n.t('community_likeFail')
      message.error({ content: msg2, key: toastKey, duration: 3 })
    };
  }

  const like = () => {
    // 没登录跳登录页
    if (!isLogin) {
      router.push(getLoginPath())
      return
    }
    if (sending) {
      message.loading({ content: I18n.t('common_submiting'), key: toastKey })
    }
    setSending(true)
    const param = {
      toCustomerId: itemData?.customerMisc?.customerId,
      customerId: userStore.userInfo.id,
      targetId: itemData.id,
      targetType: 'post',
      isLike: !isLoved,
      userStore,
      onSuccess: onLikeSuccess(isLoved),
      onFail: onLikeFail(isLoved)
    };
    likeFun(param);
  }

  return {
    like
  }
}

/**
 * （不）喜欢
 * @param param
 */
const likeFun = (param) => {
  const userId = param?.userStore?.userInfo?.id
  getClient()
    .reactions.add(
      'LIKE',
      param.targetId,
      !param.isLike ? { reverse: true } : {}
    )
    .then((res) => {
      if (res.result) {
        // 如果自己给自己点赞要更新用户社区相关信息
        if (userId === param.toCustomerId) {
          param.userStore.getUserSocialInfo({ userId, token: param.userStore.userInfo.jwtKey })
        }
        param.onSuccess(0);
      }
    })
    .catch((error) => { // TODO
      console.log('error:::', error)
      try {
        if (error.error.result.statusCode === 400) {
          param.needLogin && param.needLogin()
        } else {
          param.onFail(error);
        }
      } catch (e) {
        console.log('error:::', e)
        param.onFail(e);
      }
    });
}
