import { useState } from 'react';
import useAuthSession from '@lib/hooks/useAuthSession'
import { message } from 'antd';
import { getClient } from '@api/feedStream'
import { I18n, getLoginPath } from '@utils/index'
import { useRouter } from 'next/router'
// 评论
export const usePublishComment = ({ itemData = {}, content, fetchPostComment, setText, setTextLen, commentCount, setCommentCount }) => {
  const toastKey = 'updatable';
  const router = useRouter();
  const isLogin = useAuthSession({ redirectTo: null })
  const [submiting, setSubmiting] = useState(false)

  const publishComment = () => {
    // 没登录跳登录页
    if (!isLogin) {
      router.push(getLoginPath())
      return
    }
    if (submiting) return
    setSubmiting(true)
    message.loading({ content: I18n.t('community_commentSending'), key: toastKey })
    getClient()
      .reactions.add('COMMENT', itemData?.id, { text: content })
      .then((body) => {
        setSubmiting(false)
        if (body && body.result) {
          message.success({ content: I18n.t('community_commentSuccess'), key: toastKey })
          fetchPostComment({ id: itemData?.id, res: body.result })
          setText('');
          setTextLen(0);
          setCommentCount && setCommentCount(commentCount + 1);
        }
      })
      .catch((e) => {
        console.log(e);
        setSubmiting(false)
        message.error({ content: I18n.t('community_commentFail'), key: toastKey })
      });
  }
  return {
    publishComment,
    submiting
  }
}
