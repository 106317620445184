import React, { useState } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';

import cn from 'classnames';
import { inject, observer } from 'mobx-react'
import Link from 'next/link'

import { getClient } from '@api/feedStream'
import AvatarComponent from '@components/AvatarComponent';
import { COMMUNITY_PATHS } from '@constants/index'
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'
import { fontFamily } from '@styles/common'
import Comment from '@widget/post/Details/Components/Comment';
import Like from '@widget/post/Details/Components/Like';
import ReplyInput from '@widget/post/Details/Components/ReplyInput';
import { useLike } from '@widget/post/hooks/like'

import helper from '../utils/helper';
import I18n from '../utils/i18n';
import p from '../utils/Transfrom';

import commentCommentStyles from '@styleModules/commentComponentStyles.module.css';

const ReplyCommentComponent = (props) => {
  const { replyCommentData, visibleInput, setVisibleInput, itemData, fetchPostComment, userStore } = props;
  const [curLikeCount, setCurLikeCount] = useState(replyCommentData?.likeCount);
  const [isLoved, setIsLoved] = useState(replyCommentData?.like);
  const [btnVisible, setBtnVisible] = useState(false);

  const { like } = useLike({
    userStore,
    itemData: replyCommentData,
    curLikeCount,
    setCurLikeCount,
    isLoved,
    setIsLoved
  })

  const mouseEnter = () => {
    setBtnVisible(true)
  }

  const mouseLeave = () => {
    setBtnVisible(false)
  }

  return (
    <div
      className={commentCommentStyles.replyCommentComponent}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <div className={commentCommentStyles.replyCommentComponentTop}>
        <div className={commentCommentStyles.replyLeftPart}>
          <View style={styles.replyCommentHead}>
            <View style={styles.replyCommentBody}>
              <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                <Link legacyBehavior href={`${COMMUNITY_PATHS.USER_HOME}${replyCommentData?.customerMisc?.customerId}`}>
                  <span className={cn(commentCommentStyles.replyCommentAuthor, commentCommentStyles.pointer, 'DINPro-Medium')}>
                    {replyCommentData.customerMisc.nickName}
                  </span>
                </Link>
                {
                  (
                    replyCommentData.targetCustomerMisc.nickName &&
                    replyCommentData.verb === 'REPLY'
                  )
                    ? (
                      <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                        <View style={{ marginLeft: 8, marginRight: 8 }}>
                          <span className={cn(commentCommentStyles.replyText, 'Medium')}>{I18n.t('community_reply')}</span>
                        </View>
                        <Link legacyBehavior href={`${COMMUNITY_PATHS.USER_HOME}${replyCommentData?.targetCustomerMisc?.customerId}`}>
                          <span className={cn(commentCommentStyles.replyCommentAuthor, commentCommentStyles.pointer, 'DINPro-Medium')}>
                            {replyCommentData.targetCustomerMisc.nickName}
                          </span>
                        </Link>
                      </View>
                      )
                    : null}
                <Text style={styles.time}>
                  <span className='DINPro'>
                    {helper.toLocaleTime(replyCommentData.timestamp, 'MM/DD HH:mm')}
                  </span>
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.replyCommentRightBlock}>
            <Text style={styles.text}>
                <span
                  className={cn('DINPro', commentCommentStyles.text)}
                  dangerouslySetInnerHTML={{ __html: replyCommentData?.text }}
                />
            </Text>
          </View>
        </div>
        <div className={cn(commentCommentStyles.replyRightPart)} style={btnVisible ? { opacity: 1 } : { opacity: 0 }}>
          <div>
            <Like
              type='3'
              curLikeCount={curLikeCount}
              isLoved={isLoved}
              likeFun={like}
            />
          </div>
          <div>
            <Comment
              type='3'
              commentCount={replyCommentData.customerMisc?.replyCount}
              commentFun={() => {
                if (visibleInput === replyCommentData.id) {
                  setVisibleInput('')
                } else {
                  setVisibleInput(replyCommentData.id)
                }
              }}
              actived={visibleInput === replyCommentData.id}
            />
          </div>
        </div>

      </div>
      <div>
        <ReplyInput
          target={replyCommentData.customerMisc.nickName}
          visible={visibleInput === replyCommentData.id}
          itemData={itemData}
          commentData={replyCommentData}
          fetchPostComment={fetchPostComment}
          setVisibleInput={setVisibleInput}
        />
      </div>
    </div>
  )
}

const CommentComponent = (props) => {
  const { userStore, hideInfo, navigate, commentData, visibleInput, setVisibleInput, itemData, fetchPostComment, commentTextClass, actionClass, commentClass, containerStyle } = props;
  const identificationSource = helper.identificationSwitch(commentData?.customerMisc?.role);
  const [curLikeCount, setCurLikeCount] = useState(commentData?.likeCount);
  const [isLoved, setIsLoved] = useState(commentData?.like);
  const [myCommentData, setMyCommentData] = useState({});
  const [getMoreing, setGetMoreing] = useState(false);

  const { like } = useLike({
    userStore,
    itemData: commentData,
    curLikeCount,
    setCurLikeCount,
    isLoved,
    setIsLoved
  })

  const getMore = () => {
    const nextToken = myCommentData?.replies?.[myCommentData?.replies?.length - 1]?.id;
    setGetMoreing(true)
    if (getMoreing) {
      return
    }
    const requestParams = {
      reaction_id: commentData?.id,
      kind: 'REPLY',
      limit: 10,
      id_lte: nextToken,
      qs: {
        limit: 10,
        id_lte: nextToken
      }
    };
    getClient()
      .reactions.filter(requestParams)
      .then((body) => {
        if (body && body.result) {
          let res = []
          const newData = body.result;
          const tempCommentData = commentData;
          const tempReplies = tempCommentData.replies;
          if (newData.length > 0) {
            res = [...tempReplies, ...newData];
          }
          tempCommentData.replies = res;
          setMyCommentData(tempCommentData)
          setGetMoreing(false)
        }
      })
      .catch((error) => {
        console.log(error)
        setGetMoreing(false)
      });
  }

  useIsomorphicLayoutEffect(() => {
    setMyCommentData(commentData);
  }, [commentData.replies])

  return (
    <View style={StyleSheet.flatten([styles.commentComponent, containerStyle])}>
      <View style={styles.head}>
        <AvatarComponent
          customerId={commentData.customerMisc.customerId}
          avatar={commentData?.customerMisc?.avatar}
          avatarSize={32}
          identificationSource={identificationSource}
          identificationSize={12}
        />
        <View style={styles.commentBody}>
          <View style={{ alignItems: 'center', flexDirection: 'row' }}>
            <span className={cn(commentCommentStyles.author, 'DINPro-Medium')} >
              {commentData?.customerMisc?.nickName}
            </span>
            <Text style={styles.time}>
              <span className='DINPro'>{helper.toLocaleTime(commentData.timestamp, 'MM/DD HH:mm')}</span>
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.rightBlock}>
        <div className={commentClass}>
          <div className={commentTextClass}>
            <Text style={styles.text}>
                <span
                  className={cn('DINPro', commentCommentStyles.text)}
                  dangerouslySetInnerHTML={{ __html: commentData?.text }}
                />
            </Text>
          </div>
          <div className={cn(commentCommentStyles.actionGroup, actionClass)} data-name='comment-action-group'>
            <div>
              <Like
                type='3'
                curLikeCount={curLikeCount}
                isLoved={isLoved}
                likeFun={like}
              />
            </div>
            <div>
              <Comment
                type='3'
                commentCount={commentData?.replyCount}
                commentFun={() => {
                  if (visibleInput === commentData.id) {
                    setVisibleInput('')
                  } else {
                    setVisibleInput(commentData.id)
                  }
                }}
                actived={visibleInput === commentData.id}
              />
            </div>
          </div>
          <ReplyInput
            target={commentData?.customerMisc?.nickName}
            visible={visibleInput === commentData.id}
            itemData={itemData}
            commentData={commentData}
            fetchPostComment={fetchPostComment}
            setVisibleInput={setVisibleInput}
          />
          {(myCommentData.replies && myCommentData.replies.length)
            ? (
              <View style={styles.replyComment}>
                {commentData.replies?.map((item, index) => {
                  return (
                    <li key={index} className={commentCommentStyles.replyLi}>
                      <ReplyCommentComponent
                        hideInfo={hideInfo}
                        replyCommentData={item}
                        keyIndex={index}
                        {...props}
                        navigate={navigate}
                      />
                    </li>
                  )
                })}
                {commentData.replies && commentData.replyCount > commentData.replies.length
                  ? (
                    <TouchableOpacity activeOpacity={0.8}
                      style={styles.hasMoreReplyBtn}
                      onPress={() => {
                        getMore()
                      }}>
                      <Text style={styles.hasMoreReplyBtnText}>{I18n.t('K9GJbNWK')}</Text>
                    </TouchableOpacity>
                    )
                  : null}
              </View>
              )
            : null}
        </div>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  rightBlock: {
    marginLeft: p(92)
  },
  time: {
    fontSize: p(24),
    color: '#8E939F',
    marginLeft: 8,
    fontFamily: fontFamily.regular
  },
  head: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    fontSize: p(28),
    color: '#434B5F',
    lineHeight: p(44),
    wordBreak: 'break-all',
    fontFamily: fontFamily.regular
  },
  replyCommentAuthor: {
    fontSize: p(28)
  },
  commentBody: {
    paddingLeft: p(24),
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1
  },
  commentComponent: {
    paddingVertical: p(16)
  },
  replyComment: {
    backgroundColor: '#FBFCFD',
    borderRadius: 12,
    paddingVertical: p(32),
    paddingHorizontal: p(32),
    marginTop: 16
  },
  replyCommentBody: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1
  },
  replyCommentRightBlock: {
    paddingTop: p(10)
  },
  replyCommentHead: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  hasMoreReplyBtn: {
    marginTop: 8
  },
  hasMoreReplyBtnText: {
    color: '#6F40EE',
    fontSize: p(24)
  }
});

export default inject(rootStore => ({
  userStore: rootStore.store.userStore
}))(observer(CommentComponent))
