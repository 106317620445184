import React from 'react';
import { Icon } from '@components/index';
import styles from './index.module.css';
import cn from 'classnames';

const T = (props) => {
  const {
    isFav,
    favFun,
    curFavCount,
    className
  } = props
  const containerName = cn(styles.container, className, {
    [styles.middle]: curFavCount === 0
  })
  const countName = cn(
    styles.count, {
      [styles.countLike]: isFav
    }
  )
  return (
    <div onClick={() => { favFun() }} className={containerName} data-buried="fav">
      <Icon name='fav' className={cn(styles.fav, {
        [styles.favActive]: isFav
      })} />
      <span className={countName}>
        {!curFavCount ? ' ' : `${curFavCount}`}
      </span>
    </div>
  )
}

export default T
