import { message } from 'antd';
import { getClient } from '@api/feedStream'
import I18n from '@utils/i18n'
import { COMMUNITY_PATHS } from '@constants/index'

const striptags = require('striptags');

// 分享
export const useShare = ({ itemData = {}, setCurShareCount, sharePath = COMMUNITY_PATHS.POST_DETAIL }) => {
  const handleShareText = () => {
    let text = ''
    if (itemData.title) {
      text = itemData.title;
    } else {
      text = striptags(itemData.text)
    }
    text = text.substr(0, 50) + '..'
    const shareText = itemData?.customerMisc?.nickName + ' :' + text
    let shareLink = ''
    if (typeof window !== 'undefined') {
      shareLink = 'https://' + window?.location?.host + '/' + I18n.locale + sharePath + itemData.id;
    }

    return {
      shareText,
      shareLink
    };
  }
  const addShareCountFun = () => {
    message.success(I18n.t('share_success'));
    getClient()
      .reactions.add('SHARE', itemData.id)
      .then(() => {
        setCurShareCount(++itemData.shareCount)
      });
  }
  return {
    handleShareText,
    addShareCountFun
  }
}
