import React, { useState } from 'react';
import { Button, Input } from '@element/index'
import styles from './index.module.css';
import cn from 'classnames';
import I18n from '@utils/i18n';
import { useReply } from '@widget/post/hooks/reply'

const maxLength = 200;
const T = (props) => {
  const {
    itemData,
    commentData,
    fetchPostComment,
    target,
    visible,
    setVisibleInput
  } = props
  const [text, setText] = useState('');
  const [textLen, setTextLen] = useState(0);

  const { toReply, submiting } = useReply({
    itemData,
    commentData,
    content: text,
    fetchPostComment,
    setText,
    setTextLen,
    setVisibleInput
  })

  const onChange = (e) => {
    const val = e.target.value
    setText(val)
    setTextLen(val.length)
  }

  const lenStyle = cn(styles.textLen, {
    [styles.displayNone]: textLen === 0,
    [styles.redText]: textLen === maxLength
  })

  const containerStyle = cn(styles.container, {
    [styles.displayNone]: !visible
  })
  return (
    <div className={containerStyle} >
      <div data-name="comment-input-wrapper">
        <Input
          className={styles.input}
          value={text}
          onChange={onChange}
          maxLength={maxLength}
          prefix={<span>{`${I18n.t('community_reply')} ${target}:`}</span>}
          placeholder={' '}
          data-name="comment-input"
        />
      </div>
      <div className={styles.btnContainer}>
        <Button
          type='primary'
          content={I18n.t('community_reply')}
          className={styles.btn}
          disabled={textLen === 0 || submiting}
          onClick={async () => {
            toReply();
          }}
          data-buried='community_reply'
        />
        <span className={lenStyle}>{textLen}/{maxLength}</span>
      </div>
    </div>
  )
}

export default T
