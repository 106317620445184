import { useState } from 'react';
import useAuthSession from '@lib/hooks/useAuthSession'
import { message } from 'antd';
import { getClient } from '@api/feedStream'
import { I18n, getLoginPath } from '@utils/index'
import { useRouter } from 'next/router'
// 回复
export const useReply = (params) => {
  const { itemData = {}, commentData = {}, content, fetchPostComment, setText, setTextLen, setVisibleInput } = params
  const toastKey = 'updatable';
  const router = useRouter();
  const isLogin = useAuthSession({ redirectTo: null })
  const [submiting, setSubmiting] = useState(false)

  const toReply = () => {
    // 没登录跳登录页
    if (!isLogin) {
      router.push(getLoginPath())
      return
    }
    if (submiting) return
    setSubmiting(true)
    message.loading({ content: I18n.t('community_commentSending'), key: toastKey })
    getClient()
      .reactions.addChild('REPLY', commentData?.id, { text: content })
      .then((body) => {
        console.log(body);
        setSubmiting(false)
        setText('');
        setTextLen(0);
        if (body && body.result) {
          message.success({ content: I18n.t('community_commentSuccess'), key: toastKey })
          setVisibleInput('')
          fetchPostComment({ id: itemData?.id, res: body.result, targetComment: commentData })
        }
      })
      .catch((e) => {
        console.log(e);
        setSubmiting(false)
        message.error({ content: I18n.t('community_commentFail'), key: toastKey })
      })
  }
  return {
    toReply,
    submiting
  }
}
